import React from "react"
import classNames from "classnames"
import Img from "gatsby-image"
import { useAnimateOnce } from "../../theme/useAnimateOnce"

type BaseImageProps = Img["props"]
export interface ImageProps extends BaseImageProps {
  visible?: boolean
  animated?: boolean
  displayId?: string
}

const Image: React.FC<ImageProps> = ({
  visible,
  displayId,
  animated = true,
  className,
  ...props
}) => {
  const animationClass = useAnimateOnce(displayId, visible)

  const classes = classNames(className, animated && animationClass)

  return <Img {...props} className={classes} />
}

export default Image
