/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "../Header"
import "../../theme/global.scss"
import styles from "./Layout.module.scss"
import Footer from "../Footer"
import Helmet from "react-helmet"

type Props = {
  headerDisableSticky?: boolean
  headerDisabled?: boolean
}

const Layout: React.FC<Props> = ({
  children,
  headerDisableSticky,
  headerDisabled,
}) => {
  const data = useStaticQuery<GatsbyTypes.LayoutQuery>(graphql`
    query Layout {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Helmet>
        <noscript>
          {`
          <style>
            * {
              animation: none !important;
            }
            .main-navigation {
              position: sticky;
            }
          </style>
          `}
        </noscript>
      </Helmet>
      <div className={styles.content}>
        <main>{children}</main>
        {!headerDisabled && (
          <Header
            siteTitle={data.site?.siteMetadata?.title}
            disableSticky={headerDisableSticky}
          />
        )}
      </div>
      <Footer />
    </>
  )
}

export default Layout
