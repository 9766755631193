import React from "react"
import { useInView } from "react-intersection-observer"
import Image, { ImageProps } from "../Image"
import styles from "./BackgroundImage.module.scss"
import classNames from "classnames"

interface BackgroundImageProps extends Omit<ImageProps, "visible"> {
  dark?: boolean
  gradientTop?: boolean
  gradientBottom?: boolean
}

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  gradientTop,
  gradientBottom,
  dark,
  className,
  ...props
}) => {
  const [ref, visible] = useInView({
    rootMargin: "-10% 0px",
    triggerOnce: true,
  })

  return (
    <div className={classNames(styles.container, className)} ref={ref}>
      <Image {...props} visible={visible} className={styles.image} />

      {gradientTop && (
        <div
          className={classNames(
            styles.gradientTop,
            dark && styles.gradientTopBlack
          )}
        />
      )}

      {gradientBottom && (
        <div
          className={classNames(styles.gradient, dark && styles.gradientBlack)}
        />
      )}
    </div>
  )
}

export default BackgroundImage
