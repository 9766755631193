import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import classNames from "classnames"
import scrollTo from "gatsby-plugin-smoothscroll"
import { InViewHookResponse, useInView } from "react-intersection-observer"
import { useScrollData } from "scroll-data-hook/src"
import { ClassValue } from "classnames/types"
import styles from "./Header.module.scss"

type HeaderProps = {
  siteTitle?: string
  className?: ClassValue
  viewRef: InViewHookResponse[0]
}

type HeaderWrapperProps = {
  siteTitle?: string
  disableSticky?: boolean
}

const SPEED_THRESHOLD = 1500
const UP = "up"
const DOWN = "down"

const useHeaderClasses = (
  disableSticky: boolean,
  visible: boolean,
  entry?: IntersectionObserverEntry
) => {
  const [state, setState] = useState<string>("")
  const [dir, setDir] = useState<string>(UP)
  const { speed, direction, position } = useScrollData()

  const entryTarget = entry?.target as HTMLDivElement
  const isAbove = entryTarget ? position.y >= entryTarget?.offsetTop : false
  const isAtTop = position.y < 100

  const enabled = !visible && !isAbove

  useEffect(() => {
    if (direction.y && (direction.y === DOWN || speed.y > SPEED_THRESHOLD)) {
      setDir(direction.y)
    }
  }, [direction, speed])

  useEffect(() => {
    if (!disableSticky) {
      setState(
        classNames(
          styles.navigation,
          (isAtTop || dir === UP) && styles.show,
          enabled && styles.enabled
        )
      )
    }
  }, [isAtTop, dir, enabled])

  return state
}

const HeaderContent = React.memo<HeaderProps>(({ viewRef, className }) => (
  <>
    <nav className={classNames("main-navigation", styles.container, className)}>
      <Link to="/" activeClassName={styles.active} className={styles.link}>
        Home
      </Link>
      <Link
        to="/music/"
        activeClassName={styles.active}
        className={styles.link}
      >
        Music
      </Link>
      <Link
        to="/software/"
        activeClassName={styles.active}
        className={styles.link}
      >
        Software
      </Link>
      <Link
        to="/contact/"
        activeClassName={styles.active}
        className={styles.link}
      >
        Contact
      </Link>
    </nav>
    <div ref={viewRef} />
  </>
))

HeaderContent.displayName = "HeaderContent"

const Header = ({ disableSticky = false, ...props }: HeaderWrapperProps) => {
  const [ref, visible, entry] = useInView()

  const headerClasses = useHeaderClasses(disableSticky, visible, entry)

  return <HeaderContent {...props} className={headerClasses} viewRef={ref} />
}

export default Header
