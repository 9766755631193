import { useEffect, useState } from "react"
import animatedStyles from "./Animated.module.scss"
import { ClassValue } from "classnames/types"

const getItem = (key: string): string | null =>
  typeof window !== "undefined" ? window.sessionStorage.getItem(key) : null

const setItem = (key: string, value: string): void =>
  typeof window !== "undefined"
    ? window.sessionStorage.setItem(key, value)
    : void 0

const getStorageId = (displayId: string) => `shown-${displayId}`

const useHasBeenVisible = (displayId?: string, visible?: boolean) => {
  const id = displayId && getStorageId(displayId)

  const [hasBeenVisible, setHasBeenVisible] = useState(!!(id && getItem(id)))

  useEffect(() => {
    if (id) {
      setHasBeenVisible(Boolean(getItem(id)))
    }
  }, [id])

  useEffect(() => {
    if (id && visible) {
      setItem(id, "true")
    }
  }, [id, visible])

  return hasBeenVisible
}

export const useAnimateOnce = (
  displayId?: string,
  visible?: boolean
): ClassValue => {
  const shown =
    typeof window === "undefined" || useHasBeenVisible(displayId, visible)

  const animationClass = visible
    ? animatedStyles.visible
    : animatedStyles.animated

  return shown ? animatedStyles.shown : animationClass
}
