import React from "react"
import styles from "./Footer.module.scss"
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const Footer: React.FC = () => (
  <footer className={styles.footer} id="contact">
    <div className={styles.copyright}>
      <p>
        Image credits:{" "}
        <OutboundLink
          href="https://unsplash.com/@jeremybishop"
          rel="noopener noreferrer"
          target="_blank"
        >
          Jeremy Bishop
        </OutboundLink>
        ,{" "}
        <OutboundLink
          href="https://unsplash.com/@bernardhermant"
          rel="noopener noreferrer"
          target="_blank"
        >
          Bernard Hermant
        </OutboundLink>
        ,<br/>
        <OutboundLink
          href="https://pixabay.com/users/pexels-2286921"
          rel="noopener noreferrer"
          target="_blank"
        >
          Pexels
        </OutboundLink>
        ,{" "}
        <OutboundLink
          href="https://unsplash.com/@joelfilip"
          rel="noopener noreferrer"
          target="_blank"
        >
          Joel Filipe
        </OutboundLink>{" "}
        and{" "}
        <OutboundLink
          href="https://unsplash.com/@tombrand"
          rel="noopener noreferrer"
          target="_blank"
        >
          Tomasz Filipek
        </OutboundLink>
      </p>
    </div>
    <div className={styles.row}>
      <div className={styles.links}>
        <OutboundLink
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://facebook.com/aleksi.munter"
        >
          <FaFacebook className={styles.icon} />
        </OutboundLink>
        <OutboundLink
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://instagram.com/aleksimunter"
        >
          <FaInstagram className={styles.icon} />
        </OutboundLink>
        <OutboundLink
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://linkedin.com/in/aleksimunter/"
        >
          <FaLinkedinIn className={styles.icon} />
        </OutboundLink>
        <OutboundLink
          className={styles.link}
          rel="noopener noreferrer"
          target="_blank"
          href="https://twitter.com/AleksiMunter"
        >
          <FaTwitter className={styles.icon} />
        </OutboundLink>
      </div>
      <div className={styles.copyright}>
        Aleksi&nbsp;Munter&nbsp;&copy;&nbsp;2020
      </div>
    </div>
  </footer>
)

export default Footer
